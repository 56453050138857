import { Link } from "react-router-dom";
import "./ControlBar.scss";

interface ControlBarProps {
  path: string;
}

function ControlBar(props: ControlBarProps): JSX.Element {
  return (
    <div className="control-bar">
      <Link to={{ pathname: props.path }}>
        <bds-tooltip position="right-center" tooltip-text="Voltar">
          <bds-icon class="return-button" name="arrow-ball-left" size="xx-large" theme="solid">
            {" "}
          </bds-icon>
        </bds-tooltip>
      </Link>
    </div>
  );
}

export default ControlBar;
