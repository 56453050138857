import { BlipCommandBaseRequest } from "../../../base/models/BlipCommandBaseRequest";
import { Date } from "../../../../utils/Date";

export class CreateCampaignRequest extends BlipCommandBaseRequest {
  metadata: {
    "blip_portal.email": string;
  };

  resource: {
    name: string;
    campaignType: string;
    masterState: string;
    flowId: string;
    stateId: string;
    fileUrl: string;
    notificationEmail: string;
    notificationEmailLanguage: string;
    scheduled?: string;
  };

  constructor(
    userMail: string,
    name: string,
    masterState: string,
    flowId: string,
    stateId: string,
    fileUrl: string,
    scheduled?: Date
  ) {
    super(`/campaigns`, "SET", "ACTIVE_CAMPAIGN", "ACTIVE_CAMPAIGN");
    this.metadata = { "blip_portal.email": userMail };
    this.resource = {
      name: name,
      masterState: masterState,
      campaignType: "batch",
      flowId: flowId,
      stateId: stateId,
      fileUrl: fileUrl,
      notificationEmail: userMail,
      notificationEmailLanguage: "pt-BR",
    };

    if (scheduled !== null) this.resource.scheduled = Date.ToFull(scheduled!);
  }
}
