export class PhoneNumber {
  private phonePattern: RegExp = /^(\+?55)?\s?\(?(\d{2})\)?\s?(\d?)\s?(\d{4})[\s|-]?(\d{4})(\\n|$)/g;

  country?: string;
  ddd?: string;
  nine?: string;
  firstHalf?: string;
  lastHalf?: string;
  value?: string;
  isValid: boolean = true;

  constructor(phoneNumber: string) {
    this.value = phoneNumber;
  }

  public formatNumber() {
    let phoneNumber = this.value;
    let regex = new RegExp(this.phonePattern);

    phoneNumber = phoneNumber!
      .trim() //
      .replaceAll("-", "") //
      .replaceAll(" ", "") //
      .replaceAll("(", "") //
      .replaceAll(")", "");

    let result = regex.exec(phoneNumber);

    if (result === null) {
      this.isValid = false;
      this.value = phoneNumber;
      return;
    }

    let country = result[1];
    if (!country) country = "+55";
    this.country = country;

    let ddd = result[2];
    if (ddd) {
      this.ddd = ddd;
    } else {
      this.isValid = false;
    }

    let nine = result[3];
    if (nine) this.nine = nine;

    let firstHalf = result[4];
    if (firstHalf) {
      this.firstHalf = firstHalf;
    } else {
      this.isValid = false;
    }

    let lastHalf = result[5];
    if (lastHalf) {
      this.lastHalf = lastHalf;
    } else {
      this.isValid = false;
    }

    if (this.isValid)
      this.value =
        this.country + //
        this.ddd + //
        this.nine + //
        this.firstHalf + //
        this.lastHalf;
    else this.value = result[0];
  }
}
