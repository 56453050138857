import React, { useEffect } from "react";
import "./App.scss";
import { _logMain } from "../../configs/LogConfig";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "../home/Home";
import { CurrentEnvironment, CurrentLogLevel } from "../../configs/Environment";
import ListTemplate from "../template/listTemplate/ListTemplate";
import CreateTemplate from "../template/createTemplate/CreateTemplate";
import ListCampaign from "../campaign/listCampaign/ListCampaign";
import CreateCampaign from "../campaign/createCampaign/CreateCampaign";
import { AnalyticsService as _analyticsService } from "../../services/analyticsService/AnalyticsService";
import { LocalStorageService as _localStorageService } from "../../services/localStorageService/LocalStorageService";
import { Environments } from "../../constants/Environments";
import { QueryStringService as _queryStringService } from "../../services/queryStringService/QueryStringService";
import { UserContextInterface } from "../../contexts/UserContext";
import Contexts from "../../contexts/Contexts";
import NotLoggedError from "../error/notLoggedError/NotLoggedError";
import PrivateRoute from "../../components/privateRoute/PrivateRoute";
import ReportProblemButton from "../../components/reportProblem/ReportProblemButton";
import { mountKey } from "../../utils/mountKey";

const _logger = _logMain.getChildCategory("App");
const _userTraits = "userTraits";

_logger.info(`Current environment: ${CurrentEnvironment}`);
_logger.info(`Current log level: ${CurrentLogLevel}`);

var UserContextProvider: UserContextInterface;

/**
 * Only use here. This function auto track when page changed.
 */
function TrackPages() {
  let location = useLocation();

  useEffect(() => {
    if (_localStorageService.Check(_userTraits)) _analyticsService.Page();
  }, [location.pathname]);
}

/** Valid appData for tests => ?appData=ewogICJhY2NvdW50VG9rZW4iOiAiS2V5IGRHRnVaMjkzWVdKaGNtOTFkR1Z5TWpwMGQzcEhlVzkwTVhOdllrVTBkamQxV0dkWWRBPT0iLAogICJwcm9maWxlIjogewogICAgIm5hbWUiOiAiVXNlciBUZXN0IiwKICAgICJ3ZWJzaXRlIjogImh0dHA6Ly9teXdlYnNpdGUuY29tIiwKICAgICJlbWFpbCI6ICJ1c2VyQHRlc3QuY29tIiwKICAgICJwaG9uZV9udW1iZXIiOiAiMzEgOTk5OTktOTk5OSIKICB9Cn0=
 * {
  "accountToken": "Key dGFuZ293YWJhcm91dGVyMjp0d3pHeW90MXNvYkU0djd1WGdYdA==",
  "profile": {
    "name": "User Test",
    "website": "http://mywebsite.com",
    "email": "user@test.com",
    "phone_number": "31 99999-9999"
  }
}
 * @returns 
 */
function App(): JSX.Element {
  useEffect(() => {
    try {
      let appData = _queryStringService.TryGetAppData();

      const botKey = mountKey(
        appData.routerData.shortName,
        appData.routerData.accessKey
      );

      UserContextProvider = {
        botKey: botKey!,
        name: appData.profile?.name!,
        email: appData.profile?.email!,
      };

      if (Environments.IsDevelopment()) return;

      _analyticsService
        .GetUserTraitsAsync(
          botKey!,
          appData.profile?.name!,
          appData.profile?.email!
        )
        .then((resp) => {
          _logger.info("Adding user traits to local storage");
          _localStorageService.Add(_userTraits, resp);
        });
    } catch (error) {
      _logger.error("Invalid app data!");
    }
  }, []);

  TrackPages();

  const providers = new Map<string, any>();
  providers.set("user", UserContextProvider);

  return (
    <>
      <Contexts providers={providers}>
        <Routes>
          <Route //
            path="/" //
            element={<Home />}
          />
          <Route //
            path="/home" //
            element={<Home />}
          />
          <Route //
            path="/template/list" //
            element={<PrivateRoute page={<ListTemplate />} />}
          />
          <Route //
            path="/template/create" //
            element={<PrivateRoute page={<CreateTemplate />} />}
          />
          <Route //
            path="/campaign/list" //
            element={<PrivateRoute page={<ListCampaign />} />}
          />
          <Route //
            path="/campaign/create" //
            element={<PrivateRoute page={<CreateCampaign />} />}
          />
          <Route //
            path="/error" //
            element={<NotLoggedError />}
          />
          <Route //
            path="*" //
            element={<NotLoggedError />}
          />
        </Routes>

        <ReportProblemButton />
        <header> </header>
      </Contexts>
    </>
  );
}

export default App;
