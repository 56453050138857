import { GetConfigurationRequest } from "./models/getConfiguration/GetConfigurationRequest";
import { GetConfigurationResponse } from "./models/getConfiguration/GetConfigurationResponse";
import { _logService } from "../../configs/LogConfig";
import { Json } from "../../utils/Json";
import axios from "axios";
import { BlipRoute } from "../base/constants/Commands/BlipRoute";
import { BlipCommandHeader } from "../base/models/BlipCommandHeader";
import { GetStatesResponse } from "./models/getStates/GetStatesResponse";
import { GetStatesResquest as GetStatesRequest } from "./models/getStates/GetStatesRequest";

const _logger = _logService.getChildCategory("Configuration");
const msging = "@msging.net";

async function GetConfigurationsAsync(
  botKey: string,
  userMail: string,
  from?: string,
  pp?: string
): Promise<GetConfigurationResponse> {
  let log = _logger.getChildCategory("Get");

  try {
    var request = new GetConfigurationRequest(userMail, from, pp);

    log.info(`Request configurations`);
    log.debug(Json.StringifyFormat(request), "Request body.");

    const { data, status } = await axios.post<GetConfigurationResponse>(BlipRoute.Messaging, request, {
      headers: new BlipCommandHeader(botKey),
    });

    log.debug(Json.StringifyFormat(data), "Response body.");
    log.info(`Configurations received with status code ${status}.`);

    return data;
  } catch (exception: any) {
    log.error(`Error on request configurations`, () => exception);

    throw exception;
  }
}

async function GetApplicationConfiguration(botKey: string, userMail: string): Promise<any> {
  var response = await GetConfigurationsAsync(botKey, userMail);

  var application = JSON.parse(response.resource.Application);

  return application;
}

async function GetRouterIdentifier(botKey: string, userMail: string): Promise<string> {
  var application = await GetApplicationConfiguration(botKey, userMail);

  return application.identifier + msging;
}

async function GetFlowIdentifier(botKey: string, userMail: string, skillIdentifier: string): Promise<string> {
  let log = _logger.getChildCategory("GetFlowIdentifier");
  try {
    var routerIdentifier = await GetRouterIdentifier(botKey, userMail);

    log.info("Request flow identifier");

    var response = await GetConfigurationsAsync(botKey, userMail, skillIdentifier, routerIdentifier);

    var application = JSON.parse(response.resource.Application);

    var flowId = application.settings.flow.id;

    log.info(flowId, "Response body");

    return flowId;
  } catch (exception: any) {
    _logger.error(`Error on request flow identifier`, () => exception);

    throw exception;
  }
}

async function GetStatesAsync(
  botKey: string,
  userMail: string,
  skillName: string
): Promise<GetStatesResponse> {
  let log = _logger.getChildCategory("GetStates");

  try {
    var request = new GetStatesRequest(userMail, skillName);

    log.info(`Request states of ${skillName}`);
    log.debug(Json.StringifyFormat(request), "Request body.");

    const { data, status } = await axios.post<GetStatesResponse>(BlipRoute.Messaging, request, {
      headers: new BlipCommandHeader(botKey),
    });

    log.debug(Json.StringifyFormat(data), "Response body.");
    log.info(`States of of ${skillName} received with status code ${status}.`);

    return data;
  } catch (exception: any) {
    log.error(`Error on request configurations`, () => exception);

    throw exception;
  }
}

export const ConfigurationService = {
  GetConfigurationsAsync,
  GetStatesAsync,
  GetApplicationConfiguration,
  GetRouterIdentifier,
  GetFlowIdentifier,
};
