import React, { useContext } from "react";
import "./NotLoggedError.scss";
import { MessageTemplateService as _messageTemplateService } from "../../../services/messageTemplateService/MessageTemplateService";
import { AnalyticsService as _analyticsService } from "../../../services/analyticsService/AnalyticsService";
import { QueryStringService as _queryStringService } from "../../../services/queryStringService/QueryStringService";
import { _logPage } from "../../../configs/LogConfig";
import { Link } from "react-router-dom";

const _logger = _logPage.getChildCategory("ListTemplate");

function NotLoggedError(): JSX.Element {
  return (
    <>
      <div className="not-logged-error">Desculpe. O login não é válido!</div>
      <div className="not-logged-error" style={{ marginTop: "20px" }}>
        <Link className="button" to={`/`}>
          <bds-button variant="primary">Retornar ao menu inicial</bds-button>
        </Link>
      </div>
    </>
  );
}

export default NotLoggedError;
