import { InputValue } from "./InputValue";

function setError(input: InputValue, message: string): void {
  input.hasError = true;
  input.errorMessage = message;
}

function setRequired(input: InputValue): void {
  setError(input, "O campo é obrigatório!");
}

function isValid(input: InputValue, isRequired: boolean = true): boolean {
  if (input.value === "" || input.value === undefined || input.value === null || input.hasError) {
    if (isRequired) setRequired(input);
    return false;
  }
  return true;
}

function checkByRegex(input: InputValue, pattern: RegExp): boolean {
  let regex = new RegExp(pattern);
  return regex.test(input.value);
}

export const InputValidation = {
  setError,
  setRequired,
  isValid,
  checkByRegex,
};
