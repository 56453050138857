import { BlipCommandBaseRequest } from "../../../base/models/BlipCommandBaseRequest";

export class GetCampaignsRequest extends BlipCommandBaseRequest {
  metadata: {
    "blip_portal.email": string;
  };

  constructor(userMail: string, skip: number = 0, take: number = 20) {
    super(`/audience-summary?$skip=${skip}&$take=${take}&scheduled=false`, "GET", "ACTIVE_CAMPAIGN");
    this.metadata = { "blip_portal.email": userMail };
  }
}
