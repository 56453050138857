import { BlipCommandBaseRequest } from "../../../base/models/BlipCommandBaseRequest";

export class CreateTemplateRequest extends BlipCommandBaseRequest {
  resource: {
    name: string;
    components: (TemplateComponent | TemplateButtonComponent)[];
    language: string;
    category: string;
  };
  metadata: {
    "blip_portal.email": string;
    "server.shouldStore": string;
  };

  constructor(
    userMail: string,
    name: string,
    category: string,
    components: (TemplateComponent | TemplateButtonComponent)[]
  ) {
    super("/message-templates", "SET", "MESSAGING_WA", "APPLICATION_JSON");
    this.metadata = {
      "blip_portal.email": userMail,
      "server.shouldStore": "true",
    };

    this.resource = {
      name: name,
      components: components,
      language: "pt_BR",
      category: category,
    };
  }
}

export const TemplateComponentType = {
  Buttons: "BUTTONS",
  QuickReply: "QUICK_REPLY",
  Body: "BODY",
};

export class TemplateComponent {
  type: string;
  text: string;
  "$$hashKey"?: string;

  constructor(type: keyof typeof TemplateComponentType, text: string, hashKey?: string) {
    this.type = TemplateComponentType[type];
    this.text = text;
    this.$$hashKey = hashKey;
  }
}

export class TemplateButtonComponent {
  type: string;
  buttons: TemplateComponent[];

  constructor(buttons: TemplateComponent[]) {
    this.type = TemplateComponentType["Buttons"];
    this.buttons = buttons;
  }
}
