import { BannerVariant } from "blip-ds/dist/types/components/banner/banner";
import { useState } from "react";
import "./StatusBanner.scss";

interface StatusBannerProps {
  type: BannerVariant;
  message: string;
  onCloseClick: () => void;
}

function StatusBanner(props: StatusBannerProps): JSX.Element {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  return (
    <bds-banner class="status-banner" variant={props.type}>
      <div className="status-banner-text">{props.message}</div>
      <bds-button-icon variant="secondary" icon="delete" size="short" onClick={() => props.onCloseClick()} />
    </bds-banner>
  );
}

export default StatusBanner;
