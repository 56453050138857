import axios from "axios";
import { _logService } from "../../configs/LogConfig";
import { Json } from "../../utils/Json";
import { BlipRoute } from "../base/constants/Commands/BlipRoute";
import { BlipCommandHeader } from "../base/models/BlipCommandHeader";
import { GetResourceRequest } from "./models/getResource/GetResourceRequest";
import { GetResourceResponse } from "./models/getResource/GetResourceResponse";

const _logger = _logService.getChildCategory("Resource");

async function GetResource(botKey: string, resource: string): Promise<GetResourceResponse> {
  let log = _logger.getChildCategory("Get");

  try {
    var request = new GetResourceRequest(resource);

    log.info(`Request resource ${resource}`);
    log.debug(Json.StringifyFormat(request), "Request body.");

    const { data, status } = await axios.post<GetResourceResponse>(BlipRoute.Messaging, request, {
      headers: new BlipCommandHeader(botKey),
    });

    log.debug(Json.StringifyFormat(data), "Response body.");
    log.info(`Resource received with status code ${status}.`);

    return data;
  } catch (exception: any) {
    log.error(`Error on request resource ${resource}`, () => exception);

    throw exception;
  }
}

export const ResourceService = {
  GetResource,
};
