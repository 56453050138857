import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";

interface PrivateRouteProps {
  page: JSX.Element;
}

function PrivateRoute(props: PrivateRouteProps): JSX.Element {
  const userContext = useContext(UserContext);

  const isAuthenticated = userContext !== undefined;

  return isAuthenticated ? props.page : <Navigate to="/error" />;
}

export default PrivateRoute;
