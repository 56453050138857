import { FieldValidation } from "./FieldValidation";

export class VariableValidation extends FieldValidation {
  label: string = "";

  constructor(label: string, value: string) {
    super();
    this.label = label;
    this.value = value;
  }

  public setValue(value: string): void {
    this.value = value.trim();
  }
}
