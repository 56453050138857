import { ErrorOutline } from "@mui/icons-material";
import { Button, Modal } from "@mui/material";
import { useState } from "react";
import { Tooltip } from "react-tooltip";
import InputField from "../InputField/InputField";
import { InputValue } from "../InputField/models/InputValue";
import { BannerValue } from "../statusBanner/models/BannerValue";
import StatusBanner from "../statusBanner/StatusBanner";
import "./ReportProblemButton.scss";

interface ReportProblemProps {}

function ReportProblemButton(props: ReportProblemProps): JSX.Element {
  const [loader, setLoader] = useState<boolean>();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [statusBanner, setStatusBanner] = useState<BannerValue>({
    isVisible: false,
    message: "",
    type: "error",
  });
  const [reportMessage, setReportMessage] = useState<InputValue>({
    value: "",
    errorMessage: "",
    hasError: false,
  });

  async function sendMessageAsync() {
    let message = reportMessage;

    setLoader(true);
    try {
      setStatusBanner({
        ...statusBanner,
        message: "Problema reportado com sucesso!",
        isVisible: true,
        type: "info",
      });
    } catch (error) {
      setStatusBanner({
        ...statusBanner,
        message: "Erro ao reportar problema!",
        isVisible: true,
        type: "error",
      });
    }

    setLoader(false);
    setReportMessage({ ...reportMessage, value: "" });
    setOpen(false);
  }

  return (
    <>
      {loader ? <bds-loading-page /> : null}
      {statusBanner.isVisible ? (
        <StatusBanner
          message={statusBanner.message}
          type={statusBanner.type}
          onCloseClick={() => setStatusBanner({ ...statusBanner, isVisible: false })}
        />
      ) : null}
      <div id="report-problem-button" className="report-problem-button" onClick={handleOpen}>
        <ErrorOutline sx={{ fontSize: "4.1875rem" }} />
      </div>
      <Tooltip anchorId="report-problem-button" place="left" variant="dark">
        Reportar um problema
      </Tooltip>
      <Modal open={open} onClose={handleClose}>
        <div className="report-problem-modal">
          <h3>Reportar um problema</h3>
          <InputField
            inputValue={reportMessage}
            className="report-problem-message"
            rows={6}
            style={{ marginBottom: "25px" }}
            onChange={(e) =>
              setReportMessage({
                ...reportMessage,
                value: e.target.value,
              })
            }
          />
          <div className="report-problem-modal-buttons">
            <Button color="error" onClick={handleClose}>
              Cancelar
            </Button>
            <Button variant="contained" onClick={async () => sendMessageAsync()}>
              Enviar
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ReportProblemButton;
