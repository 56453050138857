import { PhoneNumber } from "./PhoneNumber";
import { VariableValidation } from "./VariableValidation";

export class TargetAudience {
  phoneNumber: PhoneNumber;
  variables: VariableValidation[];

  constructor(phoneNumber: string, variables: [string, string][]) {
    this.phoneNumber = new PhoneNumber(phoneNumber);
    this.variables = variables.map((x) => new VariableValidation(x[0], x[1]));
  }

  public Validate(): boolean {
    for (let i = 0; i < this.variables.length; i++) {
      const variable = this.variables[i];
      variable.isValid();
    }

    return this.phoneNumber.isValid || this.variables.some((x) => x.hasError);
  }

  public isVariablesValid(): boolean {
    return this.variables.every((variable) => !variable.isValid());
  }
}
