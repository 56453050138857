import { BlipCommandBaseResponse } from "../../../base/models/BlipCommandBaseResponse";

export interface GetTemplatesResponse extends BlipCommandBaseResponse {
  resource: GetTemplatesResourceResponse;
}

export interface GetTemplatesResourceResponse {
  paging: {
    cursors: {
      after: string;
      before: string;
    };
  };
  data: GetTemplatesResourceDataResponse[];
}

export interface GetTemplatesResourceDataResponse {
  id: string;
  category: string;
  components: any[];
  language: string;
  last_updated_time: Date;
  name: string;
  rejected_reason: string;
  status: string;
}

function getTextComponent(resourceData?: GetTemplatesResourceDataResponse | null): any {
  if (resourceData === null || !Template.componentsHasElements(resourceData)) return "";

  return resourceData?.components![0].text;
}

function getButtonsComponent(resourceData: GetTemplatesResourceDataResponse | null): any {
  if (resourceData === null || !Template.componentsHasElements(resourceData, 1)) return "";

  return resourceData?.components![1].buttons;
}

function componentsHasElements(
  resourceData?: GetTemplatesResourceDataResponse | null,
  numberOfElements: number = 0
) {
  return (
    resourceData === null ||
    (resourceData?.components !== undefined &&
      resourceData?.components !== null &&
      resourceData?.components.length > numberOfElements)
  );
}

export const Template = {
  componentsHasElements,
  getTextComponent,
  getButtonsComponent,
};
