import { TextField } from "@mui/material";
import { InputValue } from "./models/InputValue";

interface InputFieldProps {
  inputValue: InputValue;
  className: string;
  label?: string;
  placeholder?: string;
  rows?: number;
  style?: React.CSSProperties;
  onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

function InputField(props: InputFieldProps): JSX.Element {
  if (props.rows === null) props.rows = 1;

  return (
    <>
      <TextField
        style={props.style}
        error={props.inputValue?.hasError}
        helperText={props.inputValue?.hasError ? props.inputValue?.errorMessage : ""}
        value={props.inputValue?.value}
        inputProps={{ maxLength: 60 }}
        className={props.className}
        label={props.label}
        placeholder={props.placeholder}
        rows={props.rows ? props.rows : 1}
        multiline={props.rows! > 1}
        variant="outlined"
        onChange={(e) => (props.onChange !== null ? props.onChange!(e) : null)}
      />
    </>
  );
}

export default InputField;
