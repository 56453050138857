export const Trackings = {
  blippacksManagementGeneralMsgmoduleSaw:
    "blippacks-management-general-msgmodule-saw",
  blippacksManagementGeneralMsgmoduleRegistered:
    "blippacks-management-general-msgmodule-registered",
  blippacksMnagementGeneralMsgmoduleCampaignSent:
    "blippacks-management-general-msgmodule-campaign-sent",
  blippacksManagementGeneralMsgmoduleRegisteredSent:
    "blippacks-management-general-msgmodule-registered-sent",
  blippacksManagementGeneralMsgmoduleRegisteredQuickreply:
    "blippacks-management-general-msgmodule-registered-quickreply",
};
