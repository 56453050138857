export class DefaultUserTraits {
  moduleName: string;
  packName: string | undefined;
  botIdentifier: string;
  tenant: string;
  merchantName: string;
  userMail: string;
  screenResolution: string;
  deviceType: string;
  operationalSystem?: string | null;

  constructor(
    moduleName: string,
    botIdentifier: string,
    tenant: string,
    merchantName: string,
    userMail: string
  ) {
    this.moduleName = moduleName;
    this.userMail = userMail;
    this.botIdentifier = botIdentifier;
    this.tenant = tenant;
    this.merchantName = merchantName;
    this.screenResolution = `${window.screen.width}x${window.screen.height}`;
    this.deviceType = this.getDeviceType();
    this.operationalSystem = this.getOperationalSystem();
  }

  private getOperationalSystem() {
    var userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
      windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
      iosPlatforms = ["iPhone", "iPad", "iPod"],
      operationalSystem = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      operationalSystem = "Mac OS";
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      operationalSystem = "iOS";
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      operationalSystem = "Windows";
    } else if (/Android/.test(userAgent)) {
      operationalSystem = "Android";
    } else if (!operationalSystem && /Linux/.test(platform)) {
      operationalSystem = "Linux";
    }

    return operationalSystem;
  }

  private getDeviceType() {
    const userAgent = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent)) {
      return "tablet";
    }
    if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        userAgent
      )
    ) {
      return "mobile";
    }
    return "desktop";
  }
}
