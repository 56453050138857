export class FieldValidation {
  value: string = "";
  hasError: boolean = false;
  errorMessage: string = "";

  constructor() {
    this.value = "";
    this.hasError = false;
    this.errorMessage = "";
  }

  public setValue(value: string): void {
    this.value = value;
  }

  public setError(message: string): void {
    this.hasError = true;
    this.errorMessage = message;
  }

  public setRequired(): void {
    this.setError("O campo é obrigatório!");
  }

  public isValid(isRequired: boolean = true): boolean {
    if (this.value === "" || this.value === undefined || this.value === null || this.hasError) {
      if (isRequired) this.setRequired();
      return false;
    }
    return true;
  }
}
