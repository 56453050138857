import { UserContext } from "./UserContext";

interface ContextsProps {
  children: JSX.Element[];
  providers: Map<string, any>;
}

function Contexts(props: ContextsProps): JSX.Element {
  return (
    <>
      <UserContext.Provider value={props.providers.get("user")}>{props.children}</UserContext.Provider>
    </>
  );
}

export default Contexts;
