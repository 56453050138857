import React from "react";
import "./Home.scss";
import { _logPage } from "../../configs/LogConfig";
import { Link } from "react-router-dom";
import { AnalyticsService as _analyticsService } from "../../services/analyticsService/AnalyticsService";
import { Trackings } from "../../constants/Trackings";
// eslint-disable-next-line
const _logger = _logPage.getChildCategory("App");

function Home(): JSX.Element {
  const eventTracking = (event: string, type: string) => {
    _analyticsService.Track(event, { type: type }, true);
  };

  return (
    <div className="home">
      <bds-paper class="menu">
        <h2>Modelo de mensagem</h2>
        <div className="button-bar">
          <Link className="button" to={`template/list`}>
            <bds-button
              variant="primary"
              onClick={async () =>
                eventTracking(
                  Trackings.blippacksManagementGeneralMsgmoduleSaw,
                  "Modelo de mensagem"
                )
              }
            >
              Visualizar
            </bds-button>
          </Link>
          <Link className="button" to={`template/create`}>
            <bds-button
              variant="primary"
              onClick={async () =>
                eventTracking(
                  Trackings.blippacksManagementGeneralMsgmoduleRegistered,
                  "Modelo de mensagem"
                )
              }
            >
              Cadastrar
            </bds-button>
          </Link>
        </div>
        <h2>Campanhas</h2>
        <div className="button-bar">
          <Link className="button" to={`campaign/list`}>
            <bds-button
              class="button"
              variant="primary"
              onClick={async () =>
                eventTracking(
                  Trackings.blippacksManagementGeneralMsgmoduleSaw,
                  "Campanhas"
                )
              }
            >
              Visualizar
            </bds-button>
          </Link>
          <Link className="button" to={`campaign/create`}>
            <bds-button
              class="button"
              variant="primary"
              onClick={async () =>
                eventTracking(
                  Trackings.blippacksManagementGeneralMsgmoduleRegistered,
                  "Campanhas"
                )
              }
            >
              Cadastrar
            </bds-button>
          </Link>
        </div>
      </bds-paper>
    </div>
  );
}

export default Home;
