import { LogLevel } from "typescript-logging";
import { CategoryProvider } from "typescript-logging-category-style";
import { CurrentLogLevel } from "./Environment";

const provider = CategoryProvider.createProvider("LoggerProvider", {
  level: LogLevel.toLogLevel(CurrentLogLevel),
});

/* Create some root categories for this example, you can also expose getLogger() from the provider instead e.g. */
export const _logMain = provider.getCategory("main");
export const _logPage = provider.getCategory("page");
export const _logModel = provider.getCategory("model");
export const _logService = provider.getCategory("service");
export const _logUtil = provider.getCategory("util");
