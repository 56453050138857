import { QuestionMark } from "@mui/icons-material";
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@mui/material";
import { PlacesType, Tooltip } from "react-tooltip";
import { v4 as uuidv4 } from "uuid";

interface TitleHelperProps {
  title: string;
  place: PlacesType;
  children?: JSX.Element;
}

function TitleHelper(props: TitleHelperProps): JSX.Element {
  return (
    <>
      <div className="title-helper">
        <h3 className="section-title">{props.title}</h3>
        <div id="helper-icon" className="helper">
          <QuestionMark sx={{ fontSize: 15 }} />
        </div>
        <Tooltip anchorId="helper-icon" place={props.place} variant="info">
          {props.children}
        </Tooltip>
      </div>
    </>
  );
}

export default TitleHelper;
