export const TemplateCategories = {
  TRANSACTIONAL: "Transacional",
  MARKETING: "Marketing",
  OTP: "Senhas descartáveis",
  // ACCOUNT_UPDATE: "Atualização de conta",
  // ALERT_UPDATE: "Atualização do alerta",
  // APPOINTMENT_UPDATE: "Atualização da hora marcada",
  // AUTO_REPLY: "Resposta Automática",
  // ISSUE_RESOLUTION: "Resolução do problema",
  // PAYMENT_UPDATE: "Atualização de pagamento",
  // PERSONAL_FINANCE_UPDATE: "Atualização das finanças pessoais",
  // RESERVATION_UPDATE: "Atualização da reserva",
  // SHIPPING_UPDATE: "Atualização do envio",
  // TICKET_UPDATE: "Atualização do ingresso",
};
