import React, { useContext, useEffect, useState } from "react";
import "./ListCampaign.scss";
import { MessageTemplateService as _messageTemplateService } from "../../../services/messageTemplateService/MessageTemplateService";
import { AnalyticsService as _analyticsService } from "../../../services/analyticsService/AnalyticsService";
import { QueryStringService as _queryStringService } from "../../../services/queryStringService/QueryStringService";
import { _logPage } from "../../../configs/LogConfig";
import ControlBar from "../../../components/controlBar/ControlBar";
import { CampaignService as _campaignService } from "../../../services/campaignService/CampaignService";
import {
  GetCampaignsResourceItemResponse,
  GetCampaignsResponse,
} from "../../../services/campaignService/models/getCampaigns/GetCampaignsResponse";
import { Date } from "../../../utils/Date";
import { UserContext } from "../../../contexts/UserContext";

const _logger = _logPage.getChildCategory("ListTemplate");

function ListCampaign(): JSX.Element {
  const userContext = useContext(UserContext);
  const [loader, setLoader] = useState<boolean>(true);
  const [campaigns, setCampaigns] = useState<GetCampaignsResponse>();

  useEffect(() => {
    _campaignService
      .GetCampaignsAsync(userContext!.botKey, userContext!.email) //
      .then(async (templates) => {
        setCampaigns(templates);
        setLoader(false);
      });
  }, []);

  return (
    <>
      {loader ? <bds-loading-page /> : null}
      <ControlBar path="/"></ControlBar>
      <bds-paper class="list-campaign">
        <h1>Campanhas</h1>
        <hr></hr>
        <bds-accordion-group collapse="multiple">
          {campaigns?.resource?.items?.map((res) => CampaignAccordion(res))}
        </bds-accordion-group>
      </bds-paper>
    </>
  );

  function CampaignAccordion(campaignItem: GetCampaignsResourceItemResponse): JSX.Element {
    return (
      <bds-accordion key={campaignItem.id}>
        <bds-accordion-header
          accordion-title={Date.ToBR(campaignItem.created) + " | " + campaignItem.name}
        ></bds-accordion-header>
        <bds-accordion-body>
          <div>
            <h4>Resumo de mensagens:</h4>
            <div className="message-resume">
              <bds-chip-tag color="warning">{campaignItem.total} total</bds-chip-tag>
              <bds-chip-tag color="default">{campaignItem.processed} enviadas</bds-chip-tag>
              <bds-chip-tag color="info">{campaignItem.received} receidas</bds-chip-tag>
              <bds-chip-tag color="success">{campaignItem.read} lidas</bds-chip-tag>
              <bds-chip-tag color="danger">{campaignItem.failed} falharam</bds-chip-tag>
            </div>
            <h4>Horário de envio:</h4>
            <div>
              <span>{Date.Resume(campaignItem.created)}</span>
            </div>
          </div>
        </bds-accordion-body>
      </bds-accordion>
    );
  }
}

export default ListCampaign;
