import axios from "axios";
import { _logService } from "../../configs/LogConfig";
import { Json } from "../../utils/Json";
import { BlipRoute } from "../base/constants/Commands/BlipRoute";
import { BlipCommandHeader } from "../base/models/BlipCommandHeader";
import {
  CreateTemplateRequest,
  TemplateButtonComponent,
  TemplateComponent,
} from "./models/createTemplate/CreateTemplateRequest";
import { ListTemplatesRequest } from "./models/getTemplates/GetTemplatesRequest";
import { GetTemplatesResponse } from "./models/getTemplates/GetTemplatesResponse";
import { CreateTemplateResponse } from "./models/createTemplate/CreateTemplateResponse";

const _logger = _logService.getChildCategory("MessageTemplate");

async function GetTemplatesAsync(
  botKey: string,
  userMail: string,
  onlyApproved: boolean = false
): Promise<GetTemplatesResponse> {
  let log = _logger.getChildCategory("GetAll");

  try {
    var request = new ListTemplatesRequest(userMail, onlyApproved);

    log.info(`Request messages templates`);
    log.debug(Json.StringifyFormat(request), "Request body.");

    const { data, status } = await axios.post<GetTemplatesResponse>(BlipRoute.Messaging, request, {
      headers: new BlipCommandHeader(botKey),
    });

    log.debug(Json.StringifyFormat(data), "Response body.");
    log.info(`Messages templates received with status code ${status}.`);

    return data;
  } catch (exception: any) {
    log.error(`Error on request messages templates`, () => exception);

    throw exception;
  }
}

async function CreateTemplateAsync(
  botKey: string,
  userMail: string,
  templateName: string,
  category: string,
  components: (TemplateComponent | TemplateButtonComponent)[]
): Promise<CreateTemplateResponse> {
  let log = _logger.getChildCategory("Create");

  try {
    var request = new CreateTemplateRequest(userMail, templateName, category, components);

    log.info(`Create message template`);
    log.debug(Json.StringifyFormat(request), "Request body.");

    const { data, status } = await axios.post<CreateTemplateResponse>(BlipRoute.Messaging, request, {
      headers: new BlipCommandHeader(botKey),
    });

    log.debug(Json.StringifyFormat(data), "Response body.");
    log.info(`Template created with status code ${status}.`);

    return data;
  } catch (exception: any) {
    log.error(`Error on request messages templates`, () => exception);

    throw exception;
  }
}

export const MessageTemplateService = {
  GetTemplatesAsync,
  CreateTemplateAsync,
};
