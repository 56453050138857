import Moment from "moment";
import "moment/locale/pt-br";

Moment.locale("pt-br");

function ToBR(date: Date) {
  return Moment(date).format("L");
}

function ToFull(date: Date) {
  return Moment(date).format("YYYY-MM-DDTHH:mm:ss.000[Z]");
}

function Resume(date: Date) {
  return Moment(date).calendar();
}

function Current() {
  return Moment().toDate();
}

export const Date = {
  ToBR,
  ToFull,
  Resume,
  Current,
};
