import * as PapaParse from "papaparse";
import { _logUtil } from "../configs/LogConfig";
import { TargetAudience } from "../models/TargetAudience";
import { Json } from "./Json";

const _logger = _logUtil.getChildCategory("CSV");

async function ParseFile(file: any): Promise<any> {
  return new Promise((resolve, reject) => {
    PapaParse.parse(file, {
      header: true,
      skipEmptyLines: true,
      transform: (value: string): string => {
        return value.trim();
      },
      complete: (results: PapaParse.ParseResult<any>, file: any) => {
        return resolve(results);
      },
    });
  });
}

function CreateFile(targetAudience: TargetAudience[]): File {
  _logger.debug("Creating blob");

  // Create fields
  const variavelLabel = "variavel";
  let fields: string[] = ["telefone"];
  for (let index = 0; index < targetAudience[0].variables.length; index++) {
    fields.push(variavelLabel + (index + 1));
  }

  _logger.trace("Fields", Json.StringifyFormat(fields));

  // Create data
  let data = [];
  for (let index = 0; index < targetAudience.length; index++) {
    let line: string[] = [];
    const element = targetAudience[index];
    line.push(element.phoneNumber.value!);

    for (let i = 0; i < element.variables.length; i++) {
      const variable = element.variables[i];
      line.push(variable.value);
    }

    data.push(line);
  }

  _logger.trace("data", Json.StringifyFormat(data));

  // Create CSV
  const csv = PapaParse.unparse({
    data,
    fields,
  });

  _logger.trace("Generated csv", csv);

  // Create blob
  const blob = new File([csv], "file", { type: "text/csv" });

  _logger.debug("Blob", blob);
  _logger.debug("Blob created");

  return blob;
}

export const CSV = {
  ParseFile,
  CreateFile,
};
