import { UploadResponse } from "./models/upload/UploadResponse";
import { GetFileUrlRequest } from "./models/getFileUrl/GetImageUrlRequest";
import { GetFileUrlResponse } from "./models/getFileUrl/GetFileUrllResponse";
import { _logService } from "../../configs/LogConfig";
import { Json } from "../../utils/Json";
import axios from "axios";
import { BlipRoute } from "../base/constants/Commands/BlipRoute";
import { BlipCommandHeader } from "../base/models/BlipCommandHeader";

const _logger = _logService.getChildCategory("File");

async function GetUrlAsync(botKey: string): Promise<GetFileUrlResponse> {
  let log = _logger.getChildCategory("GetUrl");

  try {
    var request = new GetFileUrlRequest();

    log.info(`Request file url`);
    log.debug(Json.StringifyFormat(request), "Request body.");

    const { data, status } = await axios.post<GetFileUrlResponse>(BlipRoute.Messaging, request, {
      headers: new BlipCommandHeader(botKey),
    });

    log.debug(Json.StringifyFormat(data), "Response body.");
    log.info(`File URL received with status code ${status}.`);

    return data;
  } catch (exception: any) {
    log.error(`Error on request file url`, () => exception);

    throw exception;
  }
}

async function UploadFileAsync(botKey: string, blob: Blob, url: string): Promise<UploadResponse> {
  let log = _logger.getChildCategory("Upload");

  try {
    log.info(`Upload file`);
    log.debug(blob.type, "File type");

    const { data, status } = await axios.post<UploadResponse>(url, blob, {
      headers: new BlipCommandHeader(botKey, blob.type),
    });

    log.debug(Json.StringifyFormat(data), "Response body.");
    log.info(`File uploaded with status code ${status}.`);

    return data;
  } catch (exception: any) {
    log.error(`Error on upload file`, () => exception);

    throw exception;
  }
}

async function UploadAsync(botKey: string, blob: Blob): Promise<string> {
  try {
    let url = await GetUrlAsync(botKey);

    var response = await UploadFileAsync(botKey, blob, url.resource);

    return response.mediaUri;
  } catch (exception: any) {
    throw exception;
  }
}

export const FileService = {
  GetUrlAsync,
  UploadFileAsync,
  UploadAsync,
};
