import { GetCampaignsRequest } from "./models/getCampaigns/GetCampaignsRequest";
import { GetCampaignsResponse } from "./models/getCampaigns/GetCampaignsResponse";
import { _logService } from "../../configs/LogConfig";
import { Json } from "../../utils/Json";
import axios from "axios";
import { BlipRoute } from "../base/constants/Commands/BlipRoute";
import { BlipCommandHeader } from "../base/models/BlipCommandHeader";
import { CreateCampaignRequest } from "./models/createCampaign/CreateCampaignRequest";
import { disposeEmitNodes } from "typescript";

const _logger = _logService.getChildCategory("Campaign");

async function GetCampaignsAsync(botKey: string, userMail: string): Promise<GetCampaignsResponse> {
  let log = _logger.getChildCategory("GetAll");

  try {
    var request = new GetCampaignsRequest(userMail);

    log.info(`Request campaigns`);
    log.debug(Json.StringifyFormat(request), "Request body.");

    const { data, status } = await axios.post<GetCampaignsResponse>(BlipRoute.Messaging, request, {
      headers: new BlipCommandHeader(botKey),
    });

    log.debug(Json.StringifyFormat(data), "Response body.");
    log.info(`Campaigns received with status code ${status}.`);

    return data;
  } catch (exception: any) {
    log.error(`Error on request messages templates`, () => exception);

    throw exception;
  }
}

async function CreateBatchCampaignAsync(
  botKey: string,
  userMail: string,
  name: string,
  masterState: string,
  flowId: string,
  stateId: string,
  fileUrl: string,
  scheduled?: Date
): Promise<GetCampaignsResponse> {
  let log = _logger.getChildCategory("CreateBatch");

  try {
    var request = new CreateCampaignRequest(userMail, name, masterState, flowId, stateId, fileUrl, scheduled);

    log.info(`Create campaign`);
    log.debug(Json.StringifyFormat(request), "Request body.");

    const { data, status } = await axios.post<GetCampaignsResponse>(BlipRoute.Messaging, request, {
      headers: new BlipCommandHeader(botKey),
    });

    log.debug(Json.StringifyFormat(data), "Response body.");
    log.info(`Campaign create with status code ${status}.`);

    return data;
  } catch (exception: any) {
    log.error(`Error on request messages templates`, () => exception);

    throw exception;
  }
}

export const CampaignService = {
  GetCampaignsAsync,
  CreateBatchCampaignAsync,
};
