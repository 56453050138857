import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@mui/material";
import { v4 as uuidv4 } from "uuid";

interface DropDownProps {
  hasError: boolean;
  errorMessage: string;
  label: string;
  value: string;
  onChange: any;
  children: JSX.Element[];
  className: string;
  id: string;
  disabled?: boolean;
}

function DropDown(props: DropDownProps): JSX.Element {
  return (
    <>
      <FormControl className={props.className} error={props.hasError}>
        <InputLabel id={props.id}>{props.label}</InputLabel>
        <Select
          label={props.label}
          labelId={props.id}
          value={props.value}
          disabled={props.disabled}
          onChange={(e) => props.onChange(e)}
        >
          {props.children}
        </Select>
        <FormHelperText>{props.hasError ? props.errorMessage : ""}</FormHelperText>
      </FormControl>
    </>
  );
}

export default DropDown;
