import { Text } from "../../utils/Text";

interface TextToHtmlProps {
  text: string;
}

function TextToHtml(props: TextToHtmlProps): JSX.Element {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: Text.ToHtml(props.text),
      }}
    />
  );
}

export default TextToHtml;
