import { BlipCommandBaseRequest } from "../../../base/models/BlipCommandBaseRequest";

export class ListTemplatesRequest extends BlipCommandBaseRequest {
  metadata: {
    "blip_portal.email": string;
  };

  constructor(userMail: string, onlyApproved: boolean = false) {
    super("/message-templates" + (onlyApproved ? "?status=APPROVED" : ""), "GET", "MESSAGING_WA");
    this.metadata = { "blip_portal.email": userMail };
  }
}
